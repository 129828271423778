import memoizeOne from 'memoize-one';

import { LOCAL, MAJSTG, PRD } from '../constants/environments';

const hostNameEnvMapping = {
  'local-template-cms.sunrundev.com': LOCAL,
  'majstg-template-cms.sunrun.com': MAJSTG,
  'template-cms.sunrun.com': PRD,
};

const getEnv = () => hostNameEnvMapping[window.location.hostname] || LOCAL;

export default memoizeOne(getEnv);
