import { LOCAL, MAJSTG, PRD } from './environments';

export default {
  [LOCAL]: {
    clientId: '0oaoml8n1vSF1ABCd0x7',
    authServer: 'ausoo6ufk557bTfcu0x7',
  },
  [MAJSTG]: {
    clientId: '0oaomla15pkaAZpyx0x7',
    authServer: 'ausp74z74yaSWJLKm0x7',
  },
  [PRD]: {
    clientId: '0oaomlc4ra7SHthdV0x7',
    authServer: 'default',
  },
};
